body.archive{
	.entry-medical-section{
	    .entry-medical-section-block{
			margin: 0 auto;
			max-width: 1095px;
			padding: 0 10px;
	    }
		.page-ttl{
			font-size: 3.5rem;
			text-align: center;
			line-height: 1.8;
			span{
				display: block;
				font-size: 14px;
			}
		}
	}
	#entry-medical-about{
		padding: 100px 0;
	}
	#about-block{
		@include  flex(wrap,left,space-between);
	}
	.about-block-info{
		width: 25%;
		padding: 10px;
		margin-top: 30px;
	}
	.about-block-info-img{

	}
	.about-block-info-txt{
		text-align: center;
		h3{
			padding: 20px 0 0 0;
			font-size: 18px;
		}
	}
	#entry-medical-voice{
		max-width: 1440px;
		margin: 0 auto;
		.entry-medical-section-block{
			max-width: none;
		}
	}
	#voice-block{
		background: url(../../img/entry_medical/bg.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		padding: 80px 0;
		margin-bottom: 100px;
	}
	#voice-block-btn{
		background-color: rgba(255, 255, 255, 0.9);
		margin: 0 auto;
		width: 600px;
		min-height: 220px;
		padding-top: 45px;
		padding-bottom: 20px;
		.detail-btn{
			margin: 25px auto 0;
			border-radius: 8px;
			font-size: 1rem;
			width: 400px;
			height: 50px;
			text-decoration: none;
			border: 1px solid black;
			color: black;
			background-color: white;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}
	}
	#entry-medical{
		#key-heading{
			max-width: 1200px;
			margin: 0 auto;
			padding: 20px 0;
			h1{
				margin: 0;
				font-size: 2.4rem;
			}
		}
		#key-block{
		}
		#key-img-block{
			background: url(../../img/entry_medical/key.jpg) no-repeat top center;
			width: 90vw;
			margin: 0 auto;
			background-size: cover;
		}
		#key-img{
			max-width: 1200px;
			height: 580px;
			margin: 0 auto 0;
			position: relative;
			h2{
				position: absolute;
				top: 50%;
				left: 30px;
				-ms-transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);
				font-size: 5rem;
				font-weight: bold;
				color: #fff;
				span{
					font-size: 2.8rem;
				}
			}
			svg{
				width: 40px;
				position: absolute;
				top: 50%;
				right: 30px;
				-ms-transform: translate(0, -50%);
				-webkit-transform: translate(0, -50%);
				transform: translate(0, -50%);
				path{
					fill: #fff;
				}
			}
		}
		a#key-link,
		a#key-link:link,
		a#key-link:visited,
		a#key-link:active{
			display: block;
		}
		a#key-link:hover{
			#key-img-block{
				// background-size: 110%;
			}
		}
	}
}
body.page-template{
}
body{

}

@include media(sp){
body.archive{
	.entry-medical-section{
	    .entry-medical-section-block{
	    }
		.page-ttl{
			font-size: 3.5rem;
			text-align: center;
			line-height: 1.8;
			span{
				display: block;
				font-size: 14px;
			}
		}
	}
	#entry-medical-about{
		padding: 50px 0;
	}
	#about-block{
		@include  flex(wrap,left,space-between);
	}
	.about-block-info{
		width: 50%;
		padding: 5px;
		margin-top: 20px;
	}
	.about-block-info-img{

	}
	.about-block-info-txt{
		text-align: center;
		h3{
			padding: 10px 0 0 0;
			font-size: 15px;
		}
	}
	#entry-medical-voice{
		max-width: 1440px;
		margin: 0 auto;
		.entry-medical-section-block{
			max-width: none;
			padding: 0;
		}
	}
	#voice-block{
		background: url(../../img/entry_medical/bg-sp.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		padding: 80px 0;
		margin-bottom: 100px;
	}
	#voice-block-btn{
		width: 90vw;
		.page-ttl{
			font-size: 3rem;
		}
		.detail-btn{
			width: 85vw;
			font-size: 14px;
		}
	}
	#entry-medical{
		#key-heading{
			max-width: 1200px;
			margin: 0 auto;
			padding: 20px 10px;
			h1{
				margin: 0;
				font-size: 2rem;
			}
		}
		#key-block{
		}
		#key-img-block{
			width: 100%;
		}
		#key-img{
			height: 500px;
			h2{
				font-size: 3rem;
				span{
					font-size: 2rem;
				}
			}
			svg{
				width: 30px;
				position: absolute;
				top: 50%;
				right: 30px;
			}
		}
		a#key-link,
		a#key-link:link,
		a#key-link:visited,
		a#key-link:active{
			display: block;
		}
		a#key-link:hover{
			#key-img-block{
				// background-size: 110%;
			}
		}
	}
}
}
#message-page{
	#clinic-vision{
		.bl-box01{
			padding: 20px 20px 20px 50px;
			.clinic-lead-txt{
				width: 80%;
				p{
					line-height: 1.8;
					font-size: 18px;
				}
			}
		}
	}

	#clinic-men-merit{
		padding: 100px 0;
		.clinic-men{
			@include  flex(nowrap,left,space-between);
			max-width: 1200px;
			margin: 50px auto 0;
		}
		.clinic-men-ttl{
			width: 370px;
			min-width: 370px;
			padding: 80px 50px 50px 0;
			position: relative;
			h2{
				font-size: 25px;
				line-height: 2;
			}
		}
		.clinic-men-ttl:before{
			content: "";
			width: 80px;
			height: 2px;
			display: block;
			position: absolute;
			top: 50px;
			left: 0;
			background-color: #318ad5;
		}
		.clinic-men-txt{
			padding: 80px 50px 50px;
			background: rgba(49,157,226,.1);
			.clinic-men-txt-block{
				line-height: 1.8;
				b{
					font-size: 18px;
					padding-bottom: 20px;
					display: block;
				}
				p{
					padding-bottom: 30px;
				}
			}
			.clinic-men-txt-block:last-child{
				p{
					padding-bottom: 0;
				}
			}
		}
		.clinic-merit{
			@include  flex(nowrap,left,space-between);
			max-width: 1200px;
			margin: 50px auto auto auto;
		}
		.clinic-merit-ttl{
			width: 370px;
			min-width: 370px;
			padding: 80px 0 50px 50px;
			position: relative;
			h2{
				font-size: 25px;
				line-height: 2;
			}
		}
		.clinic-merit-ttl:before{
			content: "";
			width: 80px;
			height: 2px;
			display: block;
			position: absolute;
			top: 50px;
			left: 50px;
			background-color: #318ad5;
		}
		.clinic-merit-txt{
			padding: 80px 50px 50px;
			background: rgba(49,157,226,.1);
			.clinic-merit-txt-block{
				line-height: 1.8;
				b{
					font-size: 18px;
					padding-bottom: 20px;
					display: block;
				}
				p{
					padding-bottom: 30px;
				}
			}
			.clinic-merit-txt-block:last-child{
				p{
					padding-bottom: 0;
				}
			}
		}
		.clinic-men-merit-img{
			margin: 0 auto 0;
			max-width: 1096px;
			img{
				width: 100%;
			}
		}
	}
}
@include media(sp){
	#message-page{
		#clinic-vision{
			.bl-box01{
				padding: 20px 10px;
				.clinic-lead-txt{
					width: 100%;
					p{
						font-size: 16px;
					}
				}
			}
		}

		#clinic-men-merit{
			padding: 50px 0;
			.clinic-men{
				-webkit-flex-wrap: wrap;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				margin-top: 0;
			}
			.clinic-men-ttl{
				width: auto;
				padding: 50px 10px 30px 10px;
				position: relative;
				h2{
					font-size: 25px;
					line-height: 2;
				}
			}
			.clinic-men-ttl:before{
				height: 4px;
				left: 10px;
				top: 40px;
			}
			.clinic-men-txt{
				padding: 30px 20px;
				.clinic-men-txt-block{
					b{
						padding-bottom: 10px;
					}
					p{
						padding-bottom: 20px;
					}
				}
				.clinic-men-txt-block:last-child{
					p{
					}
				}
			}
			.clinic-merit{
				-webkit-flex-wrap: wrap;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				flex-direction: column-reverse;
			}
			.clinic-merit-ttl{
				width: 100%;
				padding: 50px 10px 30px 10px;
				h2{
					font-size: 25px;
					line-height: 2;
				}
			}
			.clinic-merit-ttl:before{
				height: 4px;
				left: 10px;
				top: 40px;
			}
			.clinic-merit-txt{
				padding: 30px 20px;
				.clinic-merit-txt-block{
					b{
						padding-bottom: 10px;
					}
					p{
						padding-bottom: 20px;
					}
				}
				.clinic-merit-txt-block:last-child{
					p{
						padding-bottom: 0;
					}
				}
			}
			.clinic-men-merit-img{
				margin: 0 auto 0;
				max-width: 1096px;
				img{
					width: 100%;
				}
			}
		}
	}
}




.text-block{
	padding: 100px 0;
	.page-ttl{
		font-size: 3.5rem;
		text-align: center;
		line-height: 1.8;
		margin-bottom: 30px;
		span{
			display: block;
			font-size: 14px;
		}
	}
	.text-block-f{
		@include  flex(nowrap,left,space-between);
		max-width: 1200px;
		margin: 50px auto 0;
	}
	.text-block-f-ttl{
		width: 370px;
		min-width: 370px;
		padding: 80px 50px 50px 0;
		position: relative;
		h2{
			font-size: 25px;
			line-height: 2;
		}
	}
	.text-block-f-ttl:before{
		content: "";
		width: 80px;
		height: 2px;
		display: block;
		position: absolute;
		top: 50px;
		left: 0;
		background-color: #318ad5;
	}
	.text-block-f-txt{
		padding: 80px 50px 50px;
		background: rgba(49,157,226,.1);
		.text-block-f-txt-block{
			line-height: 1.8;
			b{
				font-size: 18px;
				padding-bottom: 20px;
				display: block;
			}
			p{
				padding-bottom: 30px;
			}
		}
		.text-block-f-txt-block:last-child{
			p{
				padding-bottom: 0;
			}
		}
	}
	.text-block-f:nth-child(even){
		// -webkit-flex-wrap: wrap;
		// -ms-flex-wrap: wrap;
		// flex-wrap: wrap;
		flex-direction: row-reverse;
		.text-block-f-ttl{
			width: 370px;
			min-width: 370px;
			padding: 80px 0 50px 50px;
			position: relative;
			h2{
				font-size: 25px;
				line-height: 2;
			}
		}
		.text-block-f-ttl:before{
			content: "";
			width: 80px;
			height: 2px;
			display: block;
			position: absolute;
			top: 50px;
			left: 50px;
			background-color: #318ad5;
		}
		.text-block-f-txt{
			padding: 80px 50px 50px;
			background: rgba(49,157,226,.1);
		}
	}
}
.basic-content{
		max-width: 1000px;
		margin: 0 auto;
		padding: 20px 0;
		.page-ttl{
			font-size: 3.5rem;
			text-align: center;
			line-height: 1.8;
			margin-bottom: 30px;
			span{
				display: block;
				font-size: 14px;
			}
		}
	.basic-content-block{
		.basic-content-block-ttl{
			font-size: 20px;
			line-height: 2;
			padding-left: 30px;
			border-left: 1px solid #197cd0;
		}
		.basic-content-block-txt{
			font-size: 16px;
			line-height: 2;
			padding: 22px 30px 27px;
		}
	}
}
@include media(sp){
	.basic-content{
			padding: 20px 10px;
			.page-ttl{
				span{
				}
			}
		.basic-content-block{
			.basic-content-block-ttl{
				font-size: 18px;
				line-height: 2;
				padding-left: 20px;
			}
			.basic-content-block-txt{
				font-size: 16px;
				line-height: 2;
				padding: 22px 20px 27px;
			}
		}
	}
}
@include media(sp){
	.text-block{
		padding: 50px 0;
		.text-block-f{
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			margin-top: 0;
		}
		.text-block-f-ttl{
			width: 100%;
			padding: 50px 10px 30px 10px;
			h2{
				font-size: 25px;
				line-height: 2;
			}
		}
		.text-block-f-ttl:before{
			height: 4px;
			left: 10px;
			top: 40px;
		}
		.text-block-f-txt{
			padding: 30px 20px;
			.text-block-f-txt-block{
				b{
					padding-bottom: 10px;
				}
				p{
					padding-bottom: 20px;
				}
			}
			.text-block-f-txt-block:last-child{
				p{
				}
			}
		}
		.text-block-f:nth-child(even){
			.text-block-f-ttl{
				width: 100%;
				padding: 50px 10px 30px 10px;
				h2{
					font-size: 25px;
					line-height: 2;
				}
			}
			.text-block-f-ttl:before{
				height: 4px;
				left: 10px;
				top: 40px;
			}
			.text-block-f-txt{
				padding: 30px 20px;
			}
		}
	}
}

#business{
	#key-heading{
		max-width: 1200px;
		margin: 0 auto;
		padding: 20px 0;
		h1{
			margin: 0;
			font-size: 2.4rem;
			span{
				font-size: 1.4rem;
				display: block;
				padding-top: 10px;
			}
		}
	}
	#business-number{
		#business-number-block{
			@include flex(wrap,left,space-between);
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-align: stretch;
            align-items: stretch;
			max-width: 1200px;
			margin: 0 auto;
			.numbers-block{
				border: 1px solid #dddddd;
				border-radius: 20px;
				padding: 30px 30px;
				background: #fff;
				.number{
					// color:#309de2;
					color: #1075b5;
					span{
						color: #000;
					}
		            .count-up{
			            font-size: 5rem;
			            font-weight: 600;
						color: #1075b5;
		            }
				}
			}
			.number-icon{
				@include flex(nowrap,space-between,space-between);
	            -ms-flex-align: center;
	            align-items: center;
	            font-size: 5rem;
	            font-weight: 600;
	            border-bottom: 1px dotted #333;
	            padding:0 10px 20px 10px;
	            span{
	            	font-size: 1.4rem;
	            }
	            svg{
	            	width: 30px;
					-webkit-transform: scale(0);
					transform: scale(0);
					@include trans;
	            }
			}
			.number-txt{
				font-size: 1.2rem;
	            padding: 20px 10px 0 10px;
			}
			#business-number-ttl{
				width: 40%;
				display: flex;
				-webkit-flex-wrap: wrap;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				-ms-flex-align: center;
				align-items: center;
				-ms-flex-align: center;
				align-items: center;
				h2{
					font-size: 5rem;
					font-weight: 900;
					line-height: 1.6;
					span{
						display: block;
						font-size: 14px;
					}
				}
			}
			#staff-block{
				width: 38%;
				margin-right: 2%;
				#users{
					margin-bottom: 5%;
				}
				#staff{

				}
			}
			#sales{
				width: 20%;
				border: 1px solid #dddddd;
				border-radius: 20px;
				.numbers-block{
					border: none;
					border-radius: none;
					display: flex;
					-webkit-flex-wrap: wrap;
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;
					-webkit-align-content: space-between;
					align-content: space-between;
					height: 100%;
		            svg{
		            	width: 70px;
		            	margin: 30px auto 0 auto;
		            	display: block;
						-webkit-transform: scale(0);
						transform: scale(0);
						@include trans;
		            }
				}
				.number-icon{
					width: 100%;
					display: block;
		            font-size: 5rem;
		            font-weight: 600;
		            // border-bottom: 1px dotted #333;
		            border: none;
		            padding:0 10px 0 10px;
		            span{
		            }
					.number{
						padding-bottom: 20px;
						border-bottom: 1px dotted #333;
						span{
							display: inline;
		            		font-size: 1.4rem;
		            		line-height: 1;
						}
			            .count-up{
				            font-size: 5rem;
				            font-weight: 600;
							color: #1075b5;
			            }
						.year{
							margin-top: 5px;
							display: block;
						}
					}
				}
			}
			#users.show,#staff.show,#sales.show
			// ,#sex.show,#point.show
			{
				.number-icon{
		            svg{
						-webkit-transform: scale(1);
						transform: scale(1);
		            }
		        }
		        .numbers-block{
		            svg{
						-webkit-transform: scale(1);
						transform: scale(1);
		            }
		        }
			}
		}
		#sex-point{
			@include flex(wrap,left,space-between);
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-align: stretch;
            align-items: stretch;
			max-width: 1200px;
			margin: 30px auto;
			.numbers-block{
				border: 1px solid #dddddd;
				border-radius: 20px;
				padding: 30px 30px;
				background: #fff;
	            svg{
	            	width: 40px;
	            }
				.number{
					// color:#309de2;
					color: #1075b5;
					span{
						color: #000;
					}
		            .count-up{
			            font-size: 5rem;
			            font-weight: 600;
						color: #1075b5;
		            }
				}
			}
			.number-icon{
				@include flex(nowrap,space-between,space-between);
	            -ms-flex-align: center;
	            align-items: center;
	            font-size: 5rem;
	            font-weight: 600;
	            border-bottom: 1px dotted #333;
	            padding:0 10px 20px 10px;
	            span{
	            	font-size: 1.4rem;
	            }
	            svg{
	            	width: 30px;
					-webkit-transform: scale(0);
					transform: scale(0);
					@include trans;
	            }
			}
			.number-txt{
				font-size: 1.2rem;
	            padding: 20px 10px 0 10px;
			}
			#sex{
				width: 49%;
				margin-right: 1%;
				.numbers-block{
		            svg{
		            	width: 40px;
						-webkit-transform: scale(0);
						transform: scale(0);
						@include trans;
		            }
		        }
			}
			#point{
				width: 49%;
				margin-left: 1%;
			}
			#sales.show,#sex.show,#point.show{
				.number-icon{
		            svg{
						-webkit-transform: scale(1);
						transform: scale(1);
		            }
		        }
		        .numbers-block{
		            svg{
						-webkit-transform: scale(1);
						transform: scale(1);
		            }
		        }
			}
		}
	}
	.un-about-history-inner{
		padding: 50px 0;
		.page-ttl{
			font-size: 3.5rem;
			text-align: center;
			line-height: 1.8;
			margin-bottom: 30px;
			span{
				display: block;
				font-size: 14px;
			}
		}
	}
	#service{
		padding: 50px 0;
		.page-ttl{
			font-size: 3.5rem;
			text-align: center;
			line-height: 1.8;
			margin-bottom: 30px;
			span{
				display: block;
				font-size: 14px;
			}
		}
	}
	.service-img{
		text-align: center;
		max-width: 1200px;
		margin: auto;
		img{
			width: 100%;
		}
	}
	#service-grf{
		margin-top: 50px;
	}
}

#voice{
	#key-heading{
		max-width: 1200px;
		margin: 0 auto;
		padding: 20px 0;
		h1{
			margin: 0;
			font-size: 2.4rem;
			span{
				font-size: 1.4rem;
				padding-top: 10px;
				display: block;
			}
		}
	}
	.page-ttl{
		font-size: 3.5rem;
		text-align: center;
		line-height: 1.8;
		margin-bottom: 30px;
		span{
			display: block;
			font-size: 14px;
		}
	}
	.un-voice-single-block03 .hdg::before{
		content: url(../../img/entry_medical/voice/icon.svg);
	}
}
.voice-block{
	max-width: 1000px;
	margin: 0 auto;
	padding: 20px 0;
	.voice-content-block-img{
		margin: 20px 0;
	}
	.voice-content-block{
	}
	.voice-content-block-ttl{
		width: 90%;
		margin: 0 auto;
		font-size: 25px;
		padding: 10px 0 30px 0;
		line-height: 1.6;
	}
	.voice-content-block-txt{
		width: 90%;
		margin: 0 auto;
		font-size: 16px;
		line-height: 2;
	}
}


@include media(sp){
	#business{
		#key-heading{
			padding: 20px 10px;
			h1{
				margin: 0;
				font-size: 2rem;
			}
		}
		#business-number{
			padding: 10px;
			#business-number-block{
				.numbers-block{
					padding: 10px 10px;
					.number{
						span{
						}
			            .count-up{
			            }
					}
				}
				.number-icon{
		            span{
		            }
		            svg{
		            }
				}
				.number-txt{
				}
				#business-number-ttl{
					width: 100%;
					display: block;
					padding: 30px 0;
					h2{
						font-size: 3rem;
					}
				}
				#staff-block{
					width: 58%;
					#users{
					}
					#staff{

					}
				}
				#sales{
					width: 40%;
					.numbers-block{
			            svg{
			            	width: 50px;
			            }
					}
					.number-icon{
			            span{
			            }
						.number{
							span{
							}
				            .count-up{
				            }
							.year{
							}
						}
					}
				}
				#users.show,#staff.show,#sales.show
				// ,#sex.show,#point.show
				{
					.number-icon{
			            svg{
							-webkit-transform: scale(1);
							transform: scale(1);
			            }
			        }
			        .numbers-block{
			            svg{
							-webkit-transform: scale(1);
							transform: scale(1);
			            }
			        }
				}
			}
			#sex-point{
				margin: 10px auto;
				.numbers-block{
					padding: 10px;
		            svg{
		            	width: 40px;
		            }
					.number{
						// color:#309de2;
						color: #1075b5;
						span{
							color: #000;
						}
			            .count-up{
				            font-size: 5rem;
				            font-weight: 600;
							color: #1075b5;
			            }
					}
				}
				.number-icon{
		            span{
		            	font-size: 1.4rem;
		            }
		            svg{
		            	width: 30px;
						-webkit-transform: scale(0);
						transform: scale(0);
						@include trans;
		            }
				}
				.number-txt{
					font-size: 1.2rem;
		            padding: 20px 10px 0 10px;
				}
				#sex{
					width: 100%;
					margin-right: 0;
					margin-bottom: 10px;
					.numbers-block{
			            svg{
			            }
			        }
				}
				#point{
					width: 100%;
					margin-right: 0;
					.numbers-block{
						.number{
							span{
								display: block;
								padding-right: 10px;
							}
						}
					}
				}
				#sales.show,#sex.show,#point.show{
					.number-icon{
			            svg{
			            }
			        }
			        .numbers-block{
			            svg{
			            }
			        }
				}
			}
		}
		.un-about-history-inner{
			padding: 50px 0;
			.page-ttl{
				font-size: 3.5rem;
				text-align: center;
				line-height: 1.8;
				margin-bottom: 30px;
				span{
					display: block;
					font-size: 14px;
				}
			}
		}
		#service{
			padding: 50px 0;
			.page-ttl{
				font-size: 3.5rem;
				text-align: center;
				line-height: 1.8;
				margin-bottom: 30px;
				span{
					display: block;
					font-size: 14px;
				}
			}
		}
	}
}
@media screen and (min-width: 980px) {
}
@media screen and (max-width: 980px) {

}

